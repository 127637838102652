.enquiry__form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    // border-radius: 13px;
    div {
        height: 40%;
        // width: 17%;
    }
    .button_div {
        width: 20%;
    }
    @media screen and (max-width: 1134px) {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: space-evenly;
        div {
            margin-top: 14px;
            height: 25%;
            width: 30%;
            // flex-basis: 25%;
        }
        .button {
            height: 65% !important;
        }
    }
    @media screen and (max-width: 500px) {
        div {
            margin-top: 14px;
            height: 15%;
            width: 80%;
            flex-basis: 0;
        }
        .button {
            height: 75% !important;
        }
        .button_div {
            width: 80%;
        }
    }
    label {
        font-weight: 900;
        text-transform: uppercase;
    }
    .button {
        background: var(--gradient-shade);
        cursor: pointer;
        height: 50%;
        width: 100%;
        color: white;
        border: 0;
        border-radius: 11px;
        text-transform: uppercase;
        &:hover {
            background: red
        }
        &:focus {
            border: none;
        }
    }
    input {
        margin-top: 10px;
        border: 0;
        padding: 2px;
        border-bottom: 1px solid rgb(173, 172, 172);
        &:focus {
            outline: 0;
        }
        @media screen and (max-width: 950px) {
            width: 100%;
        }
    }
}

select {
    border: 0;
    width: 100%;
    margin-top: 16px !important;
    border-bottom: 1px solid rgb(173, 172, 172);
    &:focus {
        outline: 0;
        border: none;
    }
    @media screen and (max-width: 950px) {
        width: 100%;
    }
}

.message_div {
    display: none;
    width: 100%;
    text-align: center;
    font-weight: 800 !important;
    color: rgb(255, 0, 128);
}