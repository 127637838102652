
.dropdown-toggle{
    background: var(--gradient-shade);
    border: none;
    height: 2.4rem;
    width: 85%;
    margin: 10px 26px;
    color: white;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 7px;
    font-family:'Roboto', sans-serif;
    width: 100% !important;
}
.dropdown-menu{
    width: 100%;
  
}
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: pink;
    border-color: pink;
}

.btn-success:active:focus, .show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(247, 1, 255, 0.5);
}

.btn{
    margin: 0;   
}