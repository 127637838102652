.app__navbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    position: fixed;
    z-index: 2;
}

.app__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 19px;
    img{
        width: 80px;
        height: 54px;
        @media screen and (min-width: 2000px){
            width: 100px;
            height: 80px;
        }
    }
}



.app__navbar-links{
    display: flex;
    flex: 1; 
    justify-content: flex-end;
    margin-right: 92px;
    align-items: center;

    list-style: none;

    li{
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;

        div{
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;
        }

        a{
            color: rgb(138, 0, 0);
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.2rem;
            transition: all 0.3s ease-out;
            
            &:hover{
                color: rgb(255, 0, 0);
            }
        }

        &:hover{
            div{
                background-color: rgb(255, 22, 111);
            }
        }
    }

    @media screen and (max-width: 900px) {
        display: none !important;
    }
}

.app__navbar-menu{
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);

    svg{
        width: 70%;
        height: 70%;
        color: var(--white-color);
    }

    div{
        position: fixed;
        background-color: var(--secondary-color);
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        padding: 1rem;
        border-radius: 13px;
        width: 50%;
        height: 100vh;
        background-color: white;
        background-size: cover;
        background-repeat: repeat;
        box-shadow: 0 0 20px rgba(168,168,168,0.15);

        svg{
            height: 35px;
            width: 35px;
            padding: 2px;
            color: var(--secondary-color);
        }

        ul{
            display: flex;
            flex-direction: column;
            list-style: none;
            justify-content: flex-start;
            align-items: flex-start;

            li{
                margin: 1.3rem 1rem;
                a{
                    font-size: 1.5rem;
                    text-decoration: none;
                    color: var(--lightgray-color);
                    text-transform: uppercase;
                    transition: all 0.3s ease-in-out;
                    &:hover{
                        color: var(--secondary-color);
                    }
                }
            }
        }
    }

    @media screen and (min-width: 900px) {
        display: none;
    }
}

