.app__heading{
    align-self: flex-start;
    // height: 30%;
    // margin-left: 5rem;
    margin-bottom: 45px;

    @media screen and (max-width:1400px) {
        margin-left: 0px;
        
    }

    span{
        color: black;
        font-weight: bold;
        font-family:'Roboto', sans-serif !important;
        margin-left: 5px;
    }
    h1{
        color: red;
        // text-transform: uppercase;
        font-family:'Roboto', sans-serif;
        font-size: 3.5rem;
        font-weight: 900;
    }
    .heading__line{
        div{
            height: 7px;
            width: 92px;
            background: var(--gradient-shade)
        }
    }
}