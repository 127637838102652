@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');
*{
  font-family:'Roboto', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
:root {
  --font-base: 'Roboto', sans-serif;
  --gradient-shade: linear-gradient(90deg, rgba(238,41,55,1) 0%, rgba(253,29,29,1) 37%, rgba(223,20,163,1) 71%);
  --primary-color: white;
  --secondary-color: red;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --white-color: #ffffff;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}