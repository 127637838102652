

.app__container { 
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  

  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

.copyright {
  width: 100%;
  border-radius: 13px;
  height: 5rem;
  background-color: whitesmoke;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

 span {
    text-transform: uppercase;
    color: var(--black-color);
    // font-size: 1.1rem;
    
  }
  @media screen and(max-width: 350px) {
    span{
    font-size: 12px;
  }
}
}

.head-text {
  font-size: 6.5rem;
  font-weight: 800;
  color: white;

  span {
    color: var(--secondary-color);
  }
}

.p-text {
  font-size: 1.2rem;
  text-align: left;
  color:white;
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

.app__social {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  div {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 64px;
      height: 46px;
      margin: 0.5rem 1rem;

      svg {
        width: 23px;
        height: 30px;
      }
    }
  }
}

.app__special {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 55px;

  @media screen and (max-width: 625px) {
      display: none;
  }

  div {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 64px;
      height: 46px;
      margin: 0.5rem 1rem;

      svg {
        width: 23px;
        height: 30px;
      }
    }
  }
}

.app__right{
  
   @media screen and (min-width: 625px) {
       display: none;
   }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 55px;
  position: fixed;
  top: 35vh;
  left: 82%;

  div {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 64px;
      height: 46px;
      margin: 0.5rem 1rem;

      svg {
        width: 23px;
        height: 30px;
      }
    }
  }
}




.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;


  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    // background-color: rgb(187, 187, 187);
    background-color: #ff004c;
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: black;
    }

    @media screen and (min-width: 2000px) {
      width: 15px;
      height: 15px;
    }
  }
}

@media screen and (max-width: 625px) {
  .app__navigation{
    display: none;
  }

  .copyright {
    padding: 2rem;
  }
}
