
.footer__special{
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: space-evenly;

}
.app__footer{
    // z-index: 10;
    height: 50%;
    width: 100%;
    // outline: 2px solid red;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
    @media screen and (max-width: 1040px) {
        flex-direction: column;
        height: 100%;     
    }
}

.contact__us{
    
    div{
        margin-left: 0px;    
        margin-bottom: 50px;
        margin-top: 20px;
            @media screen and(max-width:920px) {
                margin-bottom: 30px;
                margin-top: 0px;
                h1{
                font-size: 2rem !important;
                }
             }
        h1{
            color: black;
            font-size: 3rem;
            font-weight: 1000;  
        }
    }
    p{
        text-align: justify;
    }
}

.contact__us{
    flex-basis: 45%;
    height: 100%;
    flex-shrink: 1;
    padding: 10px;

    @media screen and(max-width:1040px) {
            height: 20%;
    }


}

.map{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-basis: 30%;
    flex-shrink: 1;

    @media screen and(max-width:1040px) {
        width: 100%;
        flex-direction: row;
        
        .google__map{
            width: 55% !important;
            height: 85% !important;
        }
    }

    .google__map{
        height: 75%;
        padding: 2px;
        width: 55%;

    }

    a{
        text-decoration: none;
        color: rgb(255, 64, 89);
        cursor: pointer;
        font-weight: 800;
        font-size: 1.2rem;
        
        @media screen and(max-width:560px) {
            height: 61px;
            width: 100px;
            font-size: 1.3rem;
            margin-left: 10px;
        }

        &:hover{
            color: black;
            text-decoration: underline;
        }
    
    }

}

.contact__info{
    flex-shrink: 1;
    height: 100%;
    flex-basis: 25%;
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;

    .logo{
        margin-left: 26px;
        height: 5rem;
        width: 7.5rem;

        @media screen and(max-width:1040px) {
            height: 4rem;
            margin-left: 5px;
            margin-top: 10px;
            width: 6.5rem;            
        }
  
        img{
            height: 100%;
            width: 100%;
        }
    }

    .contact{
        padding: 20px;
        height: 14rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .contact_icon{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 5.3rem;
            
            svg{
                font-size: 1.3rem;
                margin-right: 20px;
                color: rgb(255, 64, 89);

            }
            a{
                cursor: pointer;
                font-weight: 800;
                text-decoration: none;
                color: black;
                
                &:hover{
                    text-decoration: underline;
                }

            }
        }

    }
}

.social_media{
    width: 15rem;
}

.phoneNumber,.mail__id{
    margin-left: 5px;
}

@media screen and(max-width: 490px) {
    .location_text{
        font-size: 12px !important;
    }
}

// .space__between{
//     align-items: space-between !important;
//     margin-top: 1rem;
// }