.expert_special{
    height: 100vh;
}

.carousel{
    height: 100% !important;
}
.carousel-inner{
    height: 100% !important;
    width: 100% !important;
    border-radius: 30px;
    box-shadow: 10px 0px 30px rgba(1,1,1,0.3);
}
.app__experts{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media screen and(max-width:800px) {
        
        .app__heading{
            
            h1{
                font-size: 2.5rem;
                
            }
        }
    }

}

.expert_content{
    flex: 55%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    

    @media screen and(max-width:850px) {
        flex-direction: column;
        .main_image{
            height: 15rem !important;
            width: 15rem !important;
            margin-top: 10px !important;
        }
        .content{
            width: 100% !important;

            
            p{
                padding: 24px;
                font-size: 14.5px !important;
            }
        }
    }
    .main_image{
        height: 25rem;
        width: 25rem;

        @media screen and(max-width:2000px) {
            height: 22rem;
            width: 22rem;
        }
        img{
            border-radius: 30px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .content{
        width: 50%;
        @media screen and(max-width:1200px) {
            width: 40%;
        }
       
        
        h1{
            margin-bottom: 20px;
            color: red;
            font-weight: 900;
        }
        p{
            text-align: justify;
            font-size: 1.2rem;
            font-weight: 600;
        }

        @media screen and(max-width:850px) {
            h1{
                margin-bottom: 0px;
                padding: 24px 0 0 24px;
            }
        }
    }
    
}
.expert_points{

    @media screen and (max-width: 1860px) {
        
        margin-top: 5rem;
    }

    @media screen and(max-width: 720px) {
        flex-direction: column;
        margin-top: 0px;
        div{
            margin-bottom: 10px;
            width: 80%;
        }
    }


    margin-top: 0px;
    width: 100%;
    height: 100%;
    flex: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    


    div{
        
        @media screen and (max-width: 1280px) {
           flex-basis: 30%;
            padding: 10px;
        }

        @media screen and (max-width: 840px) {
            h3{
                font-size: 1.3em;
            }
        }

        flex-basis: 18%;
        height: 100%;
        

        h3{
            color: rgb(255, 0, 106);
            font-weight: 800;
        }
    }
}