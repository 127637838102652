.home_special{

    background-image: url('../../assets/trial_6(final).jpg');
    width: 100vw;
    height: 100vh;
    object-fit: contain;
    background-size: 10%;
    background-repeat: no-repeat;
	background-size: cover; 
	background-position: 50% 50%;
}

.app__header{
    flex: 1;
    flex-wrap: wrap !important;
    flex-direction: row;
    padding: 6rem 2rem 0;  

  
    
    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (min-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info{

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    
    @media screen and (max-width: 1300px) {
        transform: translateX(272px) translateZ(0px) !important;
    }
    @media screen and (max-width: 960px) {
    transform: translateX(150px) translateZ(0px) !important;
    }
    @media screen and (max-width: 650px) {
       
        height: 73%;
        align-items: flex-start;
        .badge-cmp{
            // background-color: rgb(255, 255, 255);
            width: 22rem;
        }
        .info__line{
            div{

                height: 10px;
                width: 134px;
            }
            span{
                // color: rgb(255, 0, 85);
                color: rgb(0, 0, 0);
            }

        }
        span{
            font-weight: 900;
            font-size: 0.8rem !important;
        }   
        h1{
            // color: red;
            color: white;
            font-size: 4rem;
        }
        p{
            color: rgb(0, 0, 0);
            // color: rgb(255, 0, 85);
            font-weight: 900;
            font-size: 1rem;
        }
    }
    @media screen and (max-width: 650px) {
        transform: translateX(0px) translateZ(0px) !important;
        
    }
}





.app__header-badge{
    z-index: 0;
    align-self: flex-start;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (max-width: 700px) {
        justify-content: flex-end;
        align-items: flex-end;
        margin-bottom: 1rem;
    }
    
}

.badge-cmp{
    display: flex;
    padding: 1rem 2rem;
    border: var(--white-color);
    width: auto;
    flex-direction: row;
    border-radius: 8px;
    
    box-shadow: 10px 0px 30px rgba(0,0,1,0.2);

    @media screen and (max-width: 350px) {
        width: 17rem !important;
    }
}
.badge-cmp{
    span{
        font-size: 2rem;
    }
    h1{
        @media screen and (max-width: 525px) {
            font-size: 4rem;
        }
    }
    p{
        font-size: 1.5rem;

        @media screen and (max-width: 525px) {
            font-size: 1rem;
        }
    }
}
.info__line{
    display: flex;
    flex-direction: row;
    margin-top: 21px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    @media screen and (max-width:525px) {
     flex-direction: column;
        
    }
    div{
        height: 13px;
        width: 235px;
        background: var(--gradient-shade)
    }
    span{
        color: white;
        font-size: 1rem;
        @media screen and (max-width:525px) {
           margin-top: 8px;    
           }
        
        
    }
}