.about__content{
    // outline: 2px solid black;
    align-self: center;
    margin-top: 15px;
    height: 48rem;
    width: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;

    @media screen and (max-width: 1040px) {
        height: 41rem;
    }   
}

.controllers{
    display: flex;
    flex-direction: column;
    width: 27%;
    height: 85%;
    margin-top: 140px;
    
    @media screen and (max-width: 1040px) {
        .dropdown{
            display: block !important;
        }
        margin-top: 20px;
        height: 3rem;
        width: 48%;
    } 
    @media screen and (max-width: 650px) {
       width: 80%;
    }
    
    .dropdown{
        display: none;
    }
    .main__controller{
        margin-bottom: 21px;
        
        
        
        @media screen and (max-width: 1040px) {
                display: none;
        }
        


    .buttons{
        background-color: white;
        -webkit-box-shadow: 0px -3px 40px -5px rgba(0,0,0,0.45);
        -moz-box-shadow: 0px -3px 40px -5px rgba(0,0,0,0.45);
        box-shadow: 0px -3px 40px -5px rgba(0,0,0,0.45);
        
        button{
        
        height: 5rem;
        color: black;
        width: 100%;
        border: 0;
        background: white;
        border-bottom: 0.25px solid rgb(190, 190, 190);
        cursor: pointer;
        transition: all 0.25s ease-in;
        
        
        &:focus{
        border-right: 9px solid #c4025c;
        color: #c4025c;
        }
        
        div{
            margin-left: 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
            span{
                font-weight: 400;
            }
            h3{
                font-family: 'roboto';
                font-weight: 1000;
                margin-bottom: -0.2px;
            }
        }
        }
    }

}

    .about__button{
        background: var(--gradient-shade);
        border: none;
        height: 2.4rem;
        width: 85%;
        margin: 10px 29px;
                color: white;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 700;
        border-radius: 7px;
        font-family:'Roboto', sans-serif;
        cursor: pointer;

        &:hover{
            box-shadow: 10px 0px 30px rgba(0,0,1,0.2);
        }
    }
   
}

.carousell{
    // outline: 2px solid rgb(76, 0, 255);
    height: 75%;
    width: 43%;     
    overflow: hidden;
    
    @media screen and(max-width: 1400px) {
        width: 62%;
        
    }
    @media screen and (max-width: 1200px) {
        width: 63%;
        margin-bottom: 0;
    }    
    @media screen and (max-width: 1040px) {
        height: 35rem;
        width: 100%;
        overflow-y: scroll;
    }    
    
}
section{
        transition: all 0.5s ease-out;
        background-color: white;
        height: 100%;
        width: 100%;
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        
        @media screen and (max-width: 1040px) {
            align-items: center;
        }

        div:nth-child(1) {
            height: 80%;
            width: 70%;
          
        @media screen and (max-width: 1040px) {
            height: 65%;
            width: 100%;
            }
        
        @media screen and (max-width: 400px) {
                height: 40%;
             }

            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
          }

        div:nth-child(2){
            margin-left: 4px;
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            height: 52%;

            @media screen and (max-width: 400px) {
                height: 70%;
             }

        }

}
// FOR FIRST SECTION 

.content__list{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 4px;
    li{
        width: 100%;
    }
}

.section_1{
 span{
     font-weight: 800;
     font-size: 1.3rem;
     color: rgb(210, 18, 104);
 }
 p{
     text-align: justify;
     font-weight: 500;
     margin-top: 10px;
 }
    
}

.reduceWidth{
    ul{
    flex-direction: row;
    }
    li{
        width: 50%;
    }

    @media screen and(max-width:400px) {
        li{
            width: 100%;
        }
    }
}

.about_mdfc{
    padding: 7px;
    overflow-y: scroll;
    .section__content{
        height: 100%;
        width: 100%;
        p{
            text-align: justify;
            font-weight: 800;
        }
    }
}

div ::-webkit-scrollbar{
    
    width: 3px;
}
div ::-webkit-scrollbar-track{
    background: rgb(219, 219, 219);
 }
 div ::-webkit-scrollbar-thumb{
     background: #ff0055;
     border-radius: 13px;
 }
 