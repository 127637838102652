#gallery{
    min-height: 65vh;
}


.main_container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
   flex-wrap: wrap;
   width: 100%;
   height: 100%;
//    outline: 2px solid red;
   
   @media screen and(max-width:795px) {
    display: none;
}

}

@media screen and(max-width:800px) {
        
    .app__heading{
        
        h1{
            font-size: 2.5rem;
            
        }
    }
}



.image{
    height: 25rem;
    width: 25rem;
    transition: 1s;
    // border: 1px solid rgb(255, 18, 97);
    border-radius: 13px;
    // background-color: rgb(255, 18, 97);
    margin-bottom: 10px;
    cursor: pointer;
    @media screen and(max-width:955px) {
        height: 20rem;
        width: 20rem;

        
    }

    padding: 10px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:hover{
        transform: scale(1.3);
        z-index: 2;
    }
}


.gallery{
    width: 30em;
    height: 30em !important;

    @media screen and(max-width:530px) {
        width: 25em;
        height: 25em !important;
    }
    
    @media screen and(max-width: 415px) {
        width: 18em;
        height: 18em !important;
    }

    @media screen and(min-width:795px) {
        display: none;
    }
}


.carousel-inner{

    div{
        height: 100%;
        width: 100%;

        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

}